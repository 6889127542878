// SignupPage.js

import React from 'react';
import styles from './SignupPage.module.css';

const SignupPage = () => {
  return (
    <div className={styles.signupContainer}>
      {/* <img src="./img/encampoicon.png" alt="Encampo Logo" id="logo"> */}
      {/* <h2>Welcome to Encampo</h2>
        <p>Your Account was activated, now you can close this and go to enjoy Encampo App.</p>  */}
      <img src="/imgs/encampoicon.png" alt="Encampo Logo" className={styles.logo} />
      <h1>Bienvenido a Encampo</h1>
      <p>La cuenta a sido activada, cierra esta ventana, ya puedes ingresar con esta cuenta a Encampo App.</p>

      <h1><a href="https://www.youtube.com/@EncampoApp-li6bv">Tutoriales para usar Encampo App:</a></h1>
      {/* <iframe width="560" height="400" src="https://www.youtube.com/embed/yKZ-Ndh050Q" frameborder="0" allowfullscreen></iframe> */}
      <iframe
        className={styles.iframe} 
        title="Encampo App Tutorial Video"
        src="https://www.youtube.com/embed/yKZ-Ndh050Q"
        frameborder="0"
        allowfullscreen
      ></iframe>
    </div>
  );
}

export default SignupPage;
