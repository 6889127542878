// App.js

import React from 'react';
import logo from './logo.png';
import './App.css';
import SignupPage from './components/SignupPage/SignupPage';

function App() {
  // Get the current path from the window location
  const currentPath = window.location.pathname;

  // Render different components based on the current path
  let content = null;

  if (currentPath === '/SignupPage') {
    content = <SignupPage />;
  } else {
    content = (
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <h2>
            {/* Encampo is working on the website for optimal performance. */}
            Encampo is working on the website.
          </h2>

        </header>
      </div>
    );
  }

  return content;
}

export default App;
