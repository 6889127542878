// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SignupPage_signupContainer__ZFedz {
  text-align: center;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 100%; /* Ensure the container doesn't exceed the screen width */
}
.SignupPage_p__h0xEo {
    font-size: 16px; /* Adjust font size for smaller screens */
}

.SignupPage_iframe__6\\+McT {
  width: 100%;
  max-width: 100%; /* Ensure the iframe is responsive */
  height: 400px; /* Automatically adjust the height */
}

@media screen and (max-width: 480px) {
    /* Add specific styles for screens with a width of 480 pixels or less */
    .SignupPage_signupContainer__ZFedz {
        padding: 10px; /* Adjust padding for smaller screens */
    }
}

.SignupPage_logo__\\+1C5M {
  max-width: 300px; /* Make sure the logo is responsive */
  max-height: 300px;
  margin-bottom: 20px;
}


@media screen and (max-width: 480px) {
  /* Add specific styles for screens with a width of 480 pixels or less */
  .SignupPage_signupContainer__ZFedz {
      padding: 10px; /* Adjust padding for smaller screens */
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/SignupPage/SignupPage.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,uBAAuB;EACvB,aAAa;EACb,kBAAkB;EAClB,uCAAuC;EACvC,eAAe,EAAE,yDAAyD;AAC5E;AACA;IACI,eAAe,EAAE,yCAAyC;AAC9D;;AAEA;EACE,WAAW;EACX,eAAe,EAAE,oCAAoC;EACrD,aAAa,EAAE,oCAAoC;AACrD;;AAEA;IACI,uEAAuE;IACvE;QACI,aAAa,EAAE,uCAAuC;IAC1D;AACJ;;AAEA;EACE,gBAAgB,EAAE,qCAAqC;EACvD,iBAAiB;EACjB,mBAAmB;AACrB;;;AAGA;EACE,uEAAuE;EACvE;MACI,aAAa,EAAE,uCAAuC;EAC1D;AACF","sourcesContent":[".signupContainer {\n  text-align: center;\n  background-color: white;\n  padding: 20px;\n  border-radius: 8px;\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n  max-width: 100%; /* Ensure the container doesn't exceed the screen width */\n}\n.p {\n    font-size: 16px; /* Adjust font size for smaller screens */\n}\n\n.iframe {\n  width: 100%;\n  max-width: 100%; /* Ensure the iframe is responsive */\n  height: 400px; /* Automatically adjust the height */\n}\n\n@media screen and (max-width: 480px) {\n    /* Add specific styles for screens with a width of 480 pixels or less */\n    .signupContainer {\n        padding: 10px; /* Adjust padding for smaller screens */\n    }\n}\n\n.logo {\n  max-width: 300px; /* Make sure the logo is responsive */\n  max-height: 300px;\n  margin-bottom: 20px;\n}\n\n\n@media screen and (max-width: 480px) {\n  /* Add specific styles for screens with a width of 480 pixels or less */\n  .signupContainer {\n      padding: 10px; /* Adjust padding for smaller screens */\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"signupContainer": `SignupPage_signupContainer__ZFedz`,
	"p": `SignupPage_p__h0xEo`,
	"iframe": `SignupPage_iframe__6+McT`,
	"logo": `SignupPage_logo__+1C5M`
};
export default ___CSS_LOADER_EXPORT___;
